import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import "../data/sections-fragment";
export const query = graphql`
  query {
    mdx(slug: { eq: "leistung" }) {
      slug
      frontmatter {
        title
        footer {
          showContactFooter: show_contact_footer
          showContactColumn: show_contact_column
        }
        backgrounds {
          ...BackgroundFragment
        }
        sections {
          ...MdxFrontmatterSectionsFields
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Leistung",
  "backgrounds": [{
    "rows": "2 / 4",
    "gradient": "linear-gradient(to-b, rgba(255,255,255,0), gray.50)"
  }],
  "sections": [{
    "type": "header_service",
    "slug": "wie-wir-planen",
    "title": "Leistung",
    "spacing": {
      "top": ["24vh", null, "26vh", null, "33vh"]
    },
    "image": {
      "src": "images/header-unternehmen.jpg",
      "alt": "Unternehmen",
      "position": "22% 46%"
    },
    "background_image": {
      "src": "images/beton.jpg",
      "position": "100% 100%"
    },
    "decor_image": {
      "src": "images/zeichnung.png"
    },
    "columns": [{
      "headline": "Wie wir planen",
      "content": "Mit der Planung beginnt unsere Arbeit. Denn vor dem Bau einer Garage sind Fragen zu klären. **Zum Beispiel:**\n\n* Wie sind die baurechtlichen Grundlagen?\n* Welche Fläche kann genutzt werden?\n* Welchen Nutzen und welche Anforderungen soll die Garage erfüllen?\n* Welches Budget steht zur Verfügung?"
    }, {
      "headline": "",
      "content": "Sie haben solche Fragen sicherlich noch nicht oft beantwortet und tun sich vielleicht schwer. Wir beantworten diese Fragen jeden Tag. Hier unterstützen wir Sie also gerne, denn Ihre Wünsche geben die Richtung der Planung vor. Ihr persönlicher Zugewinn an Nutzen hat für uns oberste Priorität. Während der ganzen Bauzeit haben Sie feste Ansprechpartner, die Sie durch folgende **Phasen** begleiten:\n\n* Vorgespräch\n* Besichtigung vor Ort\n* Zeichnung und Statik\n\nDanach erfolgt die Herstellung der Fertigteile und wir vereinbaren einen Termin für den Aufbau. Sie können sich dann bereits auf Ihre neue Garage freuen und den neu gewonnen Platz verplanen."
    }],
    "listColumn": {
      "headline": "Das sichern wir Ihnen zu:",
      "content": "* eine passgenaue Entwicklung und Zeichnung Ihres Projektes\n* eine individuelle Berechnung der Statik für Ihr Projekt\n* eine ganzheitliche Projektbetreuung"
    }
  }, {
    "type": "cards_beta",
    "slug": "wie-wir-bauen",
    "title": "Wie wir bauen",
    "image1": {
      "src": "images/bauen-1.jpg"
    },
    "image2": {
      "src": "images/bauen-2.jpg"
    },
    "image3": {
      "src": "images/bauen-3.jpg"
    },
    "social_media_post": "src/data/social-media-posts/facebook/21-02-05-garage.yaml",
    "text": "Als Unternehmen, dass sich auf den Bau passgenauer Garagenlösungen spezialisiert hat, ist uns natürlich auch der fachgerechte Bau der Garage ein wichtiges Anliegen. Wir wählen dabei bewusst hochwertige Materialien in Form von Beton, Innendispersionsfarbe, Außenputz, Dach-Beschichtungen und mehr für Sie aus.\n\nAufgrund seiner mineralischen Zusammensetzung aus Zement, Wasser und Zuschlägen, erfüllt Beton in unserem System hohe qualitative Anforderungen. **Ihr zusätzliches Plus:** in unserem Leistungsumfang ist die statische Berechnung inbegriffen und es bedarf somit keinem zusätzlichen Statiker.\n\nZunächst sorgen wir mit einem Fundament für den sicheren Untergrund Ihrer neuen Garage. Auf Basis unserer Planungen werden parallel die Fertigteile für jede Baustelle hergestellt und anschließend bei Ihnen vor Ort aufgestellt. Dadurch entsteht Ihre neue Garage bereits an einem Tag.\n\nUnser bewährtes Team aus Fachpersonal arbeitet hier langjährig zusammen und hat schon viele Baustellen zusammen gemeistert. Jede Baustelle wird persönlich betreut."
  }, {
    "type": "cards_gamma",
    "slug": "versetzen-von-garagen",
    "title": "Versetzen von Garagen",
    "spacing": {
      "top": ["40", null, "48", null, "32"],
      "bottom": ["40", null, "72"]
    },
    "social_media_post": "src/data/social-media-posts/instagram/20-12-22-garage.yaml",
    "text": "Neues braucht Raum. Und manchmal muss man diesen erst schaffen. Wir vesetzten Ihre alte Garage am Stück. Dafür nutzen wir unseren speziellen LKW, der bis zu 16 Tonnen anheben, aufladen und wegbringen kann.\n\nDas besondere an unserem LKW ist – und da gibt es in der Region nur wenige vergleichbare – dass er mit nur 12 Meter Vorplatz auskommt und selbst wenn das knapp wird, auch die Möglichkeit zum sogenannten „Außenangriff“ der Garage über Ketten hat. Es sollte also nur sehr wenige Siuationen geben, mit denen unser LKW nicht zurechtkommt.\n\nSie brauchen sich dann nur noch entscheiden, ob Sie das gute Stück entweder auf ein neues Fundament setzen, an einen anderen Platz auf Ihrem Grundstück bringen oder direkt entsorgen lassen.",
    "contact": {
      "text": "**Wollen Sie Ihre Garage versetzen?** Ich beantworte Ihre offenen Fragen:",
      "first_name": "Florian",
      "last_name": "Kienzler",
      "image": {
        "src": "images/florian-kienzler.jpg"
      },
      "phone": {
        "url": "tel:+4976189828404",
        "label": "0761 898 284 04"
      },
      "mail": {
        "url": "mailto:info@kienzler-betontechnik.de",
        "label": "info@kienzler-betontechnik.de"
      }
    }
  }]
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      